<template>
  <div data-cy="offers-stage">
    <loader :isLoading="isLoading || appLoading" />

    <offers-empty
      v-if="!offers.length || !availableProducts?.length"
      :isSelfFunding="isClientFunded"
      :no-products="!numOfTemplateProducts"
    />
    <div v-else>
      <div
        class="flex w-full items-center mb-4"
        :class="{
          'justify-between': isLendflowUser,
          'justify-end': !isLendflowUser
        }"
      >
        <lf-h3 class="flex items-center mb-6" v-if="isLendflowUser">
          {{ $t("DEALS.DEAL_PROGRESS.SELECT_OFFER_TO_UPDATE") }}
        </lf-h3>
        <create-self-funded-offer-button
          v-if="
            (isActiveDealSelfFunded && !hasPlacementStage) ||
            (!hasPlacementStage && isLendflowUser)
          "
          :disabled="(isClient && !isUnderwritingEnabled) || isClientUser"
        />
      </div>
      <div class="flex flex-col" v-if="!isLoading">
        <lf-card
          :hasHeaderDivider="false"
          v-for="(productType, index) in availableProducts"
          :key="productType"
          class="py-6"
        >
          <template v-slot:header>
            <lf-h3>
              {{ productType }}
            </lf-h3>
            <div v-if="index === 0" class="flex items-center">
              <offer-delete-button
                :selectedOffers="selectedOffers"
                :offers="offersByProductTypes[productType]"
                :isSelfFunding="isClientFunded"
                @offerDeleted="clearSelectedOffers"
                class="mr-4"
              />
              <lf-dropdown
                v-if="selectedOffers.length"
                placeholder="Contacted Via"
                name="contacted_via_bulk"
                :options="contactVia"
                @change="handleContactViaBulk"
                class="mr-4 max-h-10"
                hidePlaceholderWhenValue
                fullWidth
              />
              <access-control :roles="canSeeEmailOfferButton">
                <primary-button :disabled="isSendDisabled" @click="sendEmail">
                  {{ $t("COMMON.EMAIL_OFFER") }}
                </primary-button>
              </access-control>
            </div>
          </template>
          <offers-table
            ref="offersTables"
            :show-product-type-name="false"
            :product-type="productType"
            :select-all="(!isFunder || isClient) && canEditDealProgress"
            :offers="offersByProductTypes[productType]"
            :select-details-provider="OfferNotePrompt"
            show-selecting-offers
            @selected-row="updateSelectedOffers"
            @selected-rows="updateSelectedOffersBulk"
            @row-clicked="openModal"
            row-is-clickable
            freeze-first-column
            show-expires-at
            show-generated-at
            select-title="Funder"
          />
        </lf-card>
        <lf-card class="py-6" v-if="declinedInfo.feedback">
          <template v-slot:header>
            <lf-h3>{{ $t("DEALS.DEAL_PROGRESS.CLIENT_FEEDBACK") }}</lf-h3>
          </template>
          <template v-slot:default>
            <div class="flex justify-between">
              <span class="text-gray-500">{{ declinedInfo.feedback }}</span>
              <span>{{ declinedInfo.date }}</span>
            </div>
          </template>
        </lf-card>
      </div>
      <div>
        <offer-info-modal
          v-if="showOfferModal"
          :close="closeModal"
          :offer="offerData"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import { useDeals } from "@/hooks/deals";
import {
  LENDFLOW_ROLE_GROUP,
  CLIENT_UNDERWRITER,
  CLIENT_ADMIN,
  PRODUCT_TYPE
} from "@/helpers/constants";
import { makeRandomString } from "@/helpers/formatting";
import {
  useActiveWorkflowTemplateStages,
  useActiveStage
} from "@/hooks/workflow";
import { PRODUCT_TYPE_TITLE_MAPPING } from "@/helpers/constants/workflow";
import { CLEAR_LF_TABLE_ROWS } from "@/helpers/constants/events";
import { useEmitter } from "@/hooks/common";
import groupBy from "lodash/groupBy";

import OfferInfoModal from "@/views/deals/modals/OfferInfoModal.vue";
import OffersTable from "@/views/deals/components/OffersTable.vue";
import OffersEmpty from "@/views/deals/components/OffersEmpty.vue";
import CreateSelfFundedOfferButton from "@/views/deals/components/CreateSelfFundedOfferButton.vue";

import type { IOffer } from "@/models/funders";
import type { IOfferDeclinedInfo } from "@/models/applications";
import type { FinancialProduct } from "@/models/common";
import type { IContactVia } from "@/models/options";
import OfferNotePrompt from "@/views/deals/components/OfferNotePrompt.vue";

type GroupedOffers = Record<string, Partial<IOffer & { mockRow?: boolean }>[]>;

const { isFunder, isClient, isClientUser } = useAuth();
const { getters, dispatch } = useStore();
const { isLendflowUser } = useAuth();
const {
  activeDeal,
  isClientFunded,
  canEditDealProgress,
  isUnderwritingEnabled,
  isActiveDealSelfFunded
} = useDeals();

const { hasPlacementStage } = useActiveWorkflowTemplateStages();
const { activeStage } = useActiveStage();
const emitter = useEmitter();

const selectedOffers = ref<string[]>([]);
const offerData = ref<null | IOffer>(null);
const showOfferModal = ref(false);
const isLoading = ref(false);
const appLoading = computed<boolean>(() => getters["applications/loading"]);
const isEmailSending = ref(false);
const offersTables = ref<InstanceType<typeof OffersTable>[]>([]);

const availableProducts = computed(
  () =>
    activeStage.value?.products?.reduce(
      (products: FinancialProduct[], product) => {
        if (
          product.enabled &&
          !!offersByProductTypes.value[PRODUCT_TYPE_TITLE_MAPPING[product.type]]
            ?.length
        ) {
          return [...products, PRODUCT_TYPE_TITLE_MAPPING[product.type]];
        }
        return products;
      },
      []
    )
);

const numOfTemplateProducts = computed(
  () => activeStage.value?.products?.filter((product) => product.enabled).length
);

const offers = computed<IOffer[]>(() => getters["applications/offers"]);

const declinedInfo = computed<IOfferDeclinedInfo>(
  () => getters["applications/offersDeclinedInfo"]
);

const clientPermissionGroup = computed(() =>
  canEditDealProgress.value ? [CLIENT_UNDERWRITER, CLIENT_ADMIN] : []
);

const canSeeEmailOfferButton = computed(() => [
  ...LENDFLOW_ROLE_GROUP,
  ...clientPermissionGroup.value
]);

const sendEmail = async (): Promise<void> => {
  try {
    isEmailSending.value = true;
    await dispatch("applications/sendOffersEmail", {
      applicationId: activeDeal.value.id
    });

    offersTables.value.forEach(({ tableRef }) => {
      if (!tableRef) {
        return;
      }
      tableRef.selectedRows = [];
    });
    selectedOffers.value = [];
  } finally {
    isEmailSending.value = false;
  }
};

const makeMockOffer = (
  availableProductTypes: string[]
): Partial<IOffer & { mockRow: boolean }> => ({
  id: makeRandomString(),
  product_type:
    availableProductTypes?.[
      Math.ceil(Math.random() * availableProductTypes.length - 1)
    ],
  mockRow: true
});

const isSendDisabled = computed(() => {
  if (isEmailSending.value) {
    return true;
  }

  return selectedOffers.value.length < 1;
});

const offersByProductTypes = computed(() => {
  let groupedOffers: GroupedOffers = groupBy(offers.value, "product_type");

  groupedOffers = {
    [PRODUCT_TYPE.TERM_LOAN]: groupedOffers[PRODUCT_TYPE.TERM_LOAN] || [],
    [PRODUCT_TYPE.LINE_OF_CREDIT]:
      groupedOffers[PRODUCT_TYPE.LINE_OF_CREDIT] || [],
    [PRODUCT_TYPE.RECEIVABLES_PURCHASE]:
      groupedOffers[PRODUCT_TYPE.RECEIVABLES_PURCHASE] || [],
    [PRODUCT_TYPE.EQUIPMENT_FINANCING]:
      groupedOffers[PRODUCT_TYPE.EQUIPMENT_FINANCING] || [],
    [PRODUCT_TYPE.ARLOC]: groupedOffers[PRODUCT_TYPE.ARLOC] || [],
    [PRODUCT_TYPE.ERC]: groupedOffers[PRODUCT_TYPE.ERC] || []
  };

  Object.keys(groupedOffers).forEach((productType) => {
    if (!groupedOffers[productType].length) {
      delete groupedOffers[productType];
    }
  });

  if (isFunder && !isClient) {
    const length = Math.ceil(Math.random() * 6);
    const availableProductTypes = Object.keys(groupedOffers);
    const mockedOffersArray = Array.from({ length }, () =>
      makeMockOffer(availableProductTypes)
    );

    groupedOffers = mockedOffersArray.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.product_type as keyof GroupedOffers]: [
          ...(acc[curr.product_type as keyof GroupedOffers] || []),
          curr
        ]
      }),
      groupedOffers
    );
  }

  return groupedOffers;
});

const contactVia = computed<IContactVia>(() => getters["options/contactVia"]);

const openModal = (offerId: string) => {
  const foundOffer = offers.value.find((item) => item.id === offerId);
  if (foundOffer) {
    offerData.value = foundOffer;
    showOfferModal.value = true;
  }
};

const closeModal = () => {
  showOfferModal.value = false;
};

const updateSelectedOffers = (id: string, checked: boolean): void => {
  if (checked) {
    selectedOffers.value = [...new Set([...selectedOffers.value, id])];
    return;
  }
  selectedOffers.value = selectedOffers.value.filter((el) => el !== id);
};

const updateSelectedOffersBulk = (
  ids: string[],
  productType: FinancialProduct
) => {
  if (ids.length) {
    selectedOffers.value = [...new Set([...selectedOffers.value, ...ids])];
    return;
  }
  const idsToRemove = offersByProductTypes.value[productType].map(
    (el) => el.id
  );
  selectedOffers.value = selectedOffers.value.filter(
    (id) => !idsToRemove.includes(id)
  );
};

const handleContactViaBulk = async (type: string | number | null) => {
  await dispatch("applications/updateContactedVia", {
    offer_ids: selectedOffers.value,
    type
  });
  await dispatch("applications/getOffers");
  clearSelectedOffers();
};

const clearSelectedOffers = () => {
  selectedOffers.value = [];
  emitter.emit(CLEAR_LF_TABLE_ROWS);
};
</script>
